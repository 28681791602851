import { UserOutlined } from "@ant-design/icons";
import { Avatar, Button, Dropdown, Menu, Space } from "antd";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { diatozLogo } from "../../assets/common";
import Trade from "../../assets/common/TradeAnalytics.svg";
import "./style.scss";

export default function Navbar() {
  const logout = () => {
    localStorage.setItem("nitiIsAuth", false);
    Navigate("/");
  };
  const Navigate = useNavigate();
  const menu = (
    <Menu
      className="logout-menu"
      items={[
        {
          label: (
            <>
              <Row className="row-container">
                <Col className="trade-image-container">
                  <img src={Trade} alt="Trade" className="tradeImage" />
                </Col>
                <Col className="diatoz-image-container">
                  <img src={diatozLogo} className="diatozImage" alt="DIATOZ" />
                </Col>
              </Row>
            </>
          ),
        },
        {
          type: "divider",
        },
        {
          label: (
            <div onClick={logout} className="tradeLogoContainer">
              <img
                className="logoutIcon"
                src={
                  "https://e2ehiring.com/images/assets/profile/new/Logout.svg"
                }
                alt="trade"
              />
              <span className="logoutSpan"> Logout</span>
            </div>
          ),
        },
      ]}
    />
  );
  const isDashboard = () =>
    window.location.href.includes("dashboard") ? true : false;
  const isRecommand = () =>
    window.location.href.includes("recommend") ? true : false;

  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
    window.history.go(1);
  };

  return (
    <div className="nav">
      <div className="container" id="dashboard">
        <div className="navbar-brand" id="diatozlogo">
          <img src={diatozLogo} alt="" />
        </div>
        <button
          className={isDashboard() ? "page-active" : "page"}
          onClick={() => Navigate("/dashboard")}
        >
          Data Visualisation
        </button>
        <button
          className={isRecommand() ? "page-active" : "page"}
          onClick={() => Navigate("/recommend")}
        >
          Dual Recommender System{" "}
        </button>

        <Dropdown
          trigger={"click"}
          className="acc-logout"
          overlay={menu}
          placement="bottomRight"
        >
          <Button className="logo-Acc-Wrapper">
            <Avatar className="AccountIcon" icon={<UserOutlined />} />
          </Button>
        </Dropdown>
      </div>
    </div>
  );
}
