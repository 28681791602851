import { ApiHelper } from "../../Helper/ApiHelper";

const apiHelper = new ApiHelper();

export class RecommenderSystemService {
  getCountryList() {
    const uri = `/v1/countrylist`;
    return apiHelper.get(uri, 1);
  }
  getRecommendation(data) {
    const uri = `/v1/recommender`;
    return apiHelper.post(uri, data, 1);
  }
}
