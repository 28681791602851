import { ApiHelper } from "../../Service/Helper/ApiHelper";

const apiHelper = new ApiHelper();

export class RegistrationService {
  addRegisterFormData(payload) {
    const uri = `/v1/signup`;
    return apiHelper.post(uri, payload);
  }
}
