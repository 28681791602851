import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import Dashboard from "./Pages/Dashboard";
import Login from "./Pages/Login";
import Register from "./Pages/Register/register";
import { PrivateRoutes } from "./Pages/PrivateRoutes";
import RecommenderSystem from "./Pages/RecommenderSystem";
import TermsAndConditions from "./Pages/TermsAndConditions/terms";
import PrivacyPolicy from "./Pages/PrivacyPolicy/policy";

function App() {
  return (
    <div className="App">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Routes>
          <Route path="/register" element={<Register />} />
          <Route path="/" element={<Login />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy-and-policy" element={<PrivacyPolicy />} />
          <Route path="dashboard" element={<PrivateRoutes>
            <Dashboard />
          </PrivateRoutes>}>
          </Route>
          <Route path="recommend" element={<PrivateRoutes>
            <RecommenderSystem />
          </PrivateRoutes>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
