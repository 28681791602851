import { useState, useEffect } from "react";
import { diatozLogo } from "../../assets/common";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import Trade from "../../assets/common/TradeAnalytics.svg";
import PhoneInput from "react-phone-input-2";
import PopupModal from "./register.popup";

import "react-phone-input-2/lib/style.css";
import { Input } from "antd";

import { Row, Col } from "antd";

import "./register.scss";
import { RegistrationService } from "./service";
import { Button } from "react-bootstrap";

function Register() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [inputNumber, setInputNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [isMobileError, setIsMobileError] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [countyDialCode, setCountyDialCode] = useState("");
  const [submitButtonEnable, setSubmitButtonDisable] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const registerFormData = new RegistrationService();

  const registrationForm = async (e) => {
    e.preventDefault();

    const payload = {
      firstName,
      lastName,
      companyName,
      email,
      mobileNumber,
      password,
      confirmPassword,
    };
    localStorage.setItem(
      "user",
      JSON.stringify({
        email,
        confirmPassword,
      })
    );
    try {
      registerFormData.addRegisterFormData(payload);
      setIsPopupVisible(true);
    } catch (error) {
      setIsPopupVisible(false);
    }
  };
  useEffect(() => {
    if (
      password === "" ||
      confirmPassword === "" ||
      firstName === "" ||
      lastName === "" ||
      companyName === "" ||
      mobileNumber === "" ||
      email === ""
    ) {
      setSubmitButtonDisable(true);
    } else if (confirmPassword.length > 0) {
      if (password !== confirmPassword) {
        setSubmitButtonDisable(true);
        setPasswordError(true);
      } else {
        setSubmitButtonDisable(false);
        setPasswordError(false);
      }
    }
  }, [
    password,
    confirmPassword,
    firstName,
    lastName,
    mobileNumber,
    companyName,
    email,
  ]);

  useEffect(() => {
    setIsEmailError(email ? !isEmail(email) : false);
  }, [email]);

  useEffect(() => {
    if (mobileNumber.length > 0) validateMobileNumber(mobileNumber);
  }, [mobileNumber]);

  const validateMobileNumber = (mobileNumber) => {
    const pattern = /^[6,7,8,9][0-9]{0,9}$/;
    if (!pattern.test(mobileNumber) || mobileNumber.length !== 10) {
      setIsMobileError(true);
    } else {
      setIsMobileError(false);
    }
  };

  const handleChange = (value, country) => {
    const validPhoneNumber = value.substring(country.dialCode.length);
    setMobileNumber(validPhoneNumber);
    setInputNumber(value);
    validateMobileNumber(mobileNumber);
    setCountyDialCode(country.dialCode.length);
  };

  return (
    <form className="main-form h-100">
      <div className="register-page h-100">
        <div className="logos-container">
          <img className="diatoz-logo" src={diatozLogo} alt="diatoz" />
          <div className="logos-separator"></div>
          <img className="logo" src={Trade} alt="niti-aayog" />
        </div>
        <div
          style={{ height: "calc(100% - 75px)" }}
          className="d-flex align-items-center"
        >
          <div className="register-container">
            <div className="register">Register</div>

            <Row className="row-container">
              <Col className="col-container-left" span={12}>
                <label className="register-label">
                  First name<span className="text-danger"> *</span>
                </label>
                <Input
                  value={firstName}
                  name="firstName"
                  onChange={(e) => setFirstName(e.target.value)}
                  className="input-text-firstname"
                  placeholder="Enter first name"
                  required
                />
              </Col>
              <Col className="col-container-right" span={12}>
                <label className="register-label">
                  Last name <span className="text-danger"> *</span>
                </label>
                <Input
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  className="input-text-lastname"
                  placeholder="Enter last name"
                  required
                />
              </Col>
            </Row>

            <div className="input-container">
              <label className="register-label">
                Company<span className="text-danger"> *</span>
              </label>

              <Input
                name="company"
                value={companyName}
                onChange={(e) => setCompany(e.target.value)}
                className="company-name-input"
                placeholder="Enter company name"
                required
              />
            </div>

            <div className="input-container">
              <label className="register-label">
                Email ID<span className="text-danger"> *</span>
              </label>

              <Input
                value={email}
                id="email"
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                className="company-name-input"
                placeholder="Enter your company email id"
                required
              />
              {isEmailError && (
                <span className="text-danger">Enter valid email id</span>
              )}
            </div>

            <div className="input-container">
              <label className="register-label">
                Mobile number<span className="text-danger"> *</span>
              </label>

              <PhoneInput
                country={"in"}
                className="company-input"
                countryCodeEditable={false}
                id="mobile"
                maxlength={10}
                international={false}
                value={inputNumber}
                enableAreaCodes={true}
                onChange={handleChange}
                required
              />
              {isMobileError && (
                <span className="text-danger">Enter correct mobile number</span>
              )}
            </div>
            <div className="password-container">
              <Row className="row-container">
                <Col className="col-container-left" span={12}>
                  <label className="register-label">
                    Password<span className="text-danger"> *</span>
                  </label>
                  <Input.Password
                    className="input-text-firstname"
                    value={password}
                    name="password"
                    placeholder="Enter password"
                    onChange={(e) => setPassword(e.target.value)}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    required
                  />
                </Col>
                <Col className="col-container-right" span={12}>
                  <label className="register-label">
                    Confirm password<span className="text-danger"> *</span>
                  </label>
                  <Input.Password
                    className="input-text-lastname"
                    name="confirmPassword"
                    placeholder="Confirm password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    iconRender={(visible) =>
                      visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                    }
                    required
                  />
                  {passwordError && (
                    <span className="text-danger">
                      Password and confirm password should match
                    </span>
                  )}
                </Col>
              </Row>
            </div>
            <div className="register-terms">
              <p>
                By clicking 'Register', you agree to our
                <Link className="term  mx-1" to={"/terms-and-conditions"}>
                  Terms of Use
                </Link>
                and
                <Link className="term mx-1" to={"/privacy-and-policy"}>
                  Privacy Policies
                </Link>
              </p>
            </div>
            <Button
              disabled={submitButtonEnable}
              onClick={(e) => registrationForm(e)}
              type="submit"
              className="register-submit"
            >
              Register
            </Button>
            <div>
              <PopupModal isVisible={isPopupVisible} />
            </div>
            <div className="terms">
              Already have an account?{" "}
              <Link className="register-button-link" to={"/"}>
                Login
              </Link>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Register;
