import axios from "axios";

const PLATFORM_URL = process.env.REACT_APP_BACKEND_URL;
const LOGOUT_URL = process.env.LOGOUT_URL;
const validStatuses = [200, 201, 400];
const BASE_HEADERS = "";

export class ApiHelper {
  async validateToken(uri) {
    try {
      const response = await axios.get(`${PLATFORM_URL}` + uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      });
      sessionStorage.setItem("isAuthenticated", "true");
      return response;
    } catch (error) {
      sessionStorage.setItem("isAuthenticated", "false");
      let err = new Error(error.response);
      return await Promise.reject(err);
    }
  }

  async get(uri, type = 0) {
    // if (Object.keys(data).length > 0) {
    //   uri = `${uri}?${qs(data)}`
    // }
    try {
      const response = await axios.get(`${PLATFORM_URL}` + uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      });
      return this.checkResponse(response);
    } catch (error) {
      return this.handleError(error);
    }
  }

  post(uri, data, type = 0) {
    return axios
      .post(`${PLATFORM_URL}` + uri, data, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }

  put(uri, data) {
    return axios
      .put(`${PLATFORM_URL}` + uri, data, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }

  delete(uri) {
    return axios
      .delete(`${PLATFORM_URL}` + uri, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }

  login(uri, data) {
    return axios
      .post(`${PLATFORM_URL}` + uri, data, {
        headers: this.getHeaders(),
        withCredentials: false,
      })
      .then((response) => {
        // store.commit('setToken', response.headers['token'] ? response.headers['token'] : '');
        sessionStorage.setItem(
          "token",
          response.data.token ? response.data.token : ""
        );
        return response;
      })
      .then(this.checkResponse)
      .catch(this.handleError);
  }

  async verifyToken(uri, token) {
    return axios
      .get(`${PLATFORM_URL}` + uri, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => res)
      .then(this.checkResponse)
      .catch(this.handleError);
  }

  logout(uri) {
    // store.commit('setToken', '');
    return axios
      .post(
        `${PLATFORM_URL}` + uri,
        {},
        {
          headers: this.getHeaders(),
          withCredentials: false,
        }
      )
      .then((response) => {
        sessionStorage.removeItem("token");
        window.location.href = `${LOGOUT_URL}`;
      })
      .catch(this.handleError);
  }

  checkResponse(response) {
    if (validStatuses.includes(response.status)) {
      return response;
    }
    // If not authorized then reset token
    // and redirect to login page
    if (response.status === 401) {
      sessionStorage.removeItem("niti-aayog-token");
      window.location.href = `${LOGOUT_URL}`;
      return Promise.reject(new Error("USER_ANONYMOUS"));
    } else {
      //store.commit("showApiError", true);
      let err = new Error(response.statusText);
      err.response = response;
      return Promise.reject(err);
    }
  }

  handleError(error) {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          localStorage.removeItem("niti-aayog-token");
          window.location.href = `${process.env.REACT_APP_BASE_URL}`;
          return Promise.reject(new Error("USER_ANONYMOUS"));
        case 400:
          return Promise.reject(error);
        case 404:
          return Promise.reject(error);
        case 409:
          return Promise.reject(error);
        default:
          //store.commit("showApiError", true);
          return Promise.reject(error);
      }
    } else {
      //store.commit("showApiError", true);
      let err = new Error(error);
      return Promise.reject(err);
    }
  }

  getHeaders(multipart = false) {
    let defaultHeaders = BASE_HEADERS;
    if (multipart) {
      defaultHeaders = {};
    }
    if (sessionStorage.getItem("niti-aayog-token")) {
      defaultHeaders = {
        authorization: "Bearer " + sessionStorage.getItem("niti-aayog-token"),
        ...defaultHeaders,
      };
    } else if (localStorage.getItem("niti-aayog-token")) {
      defaultHeaders = {
        authorization: "Bearer " + localStorage.getItem("niti-aayog-token"),
        ...defaultHeaders,
      };
    }
    return defaultHeaders;
  }
}

export default ApiHelper;
