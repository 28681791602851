import { useEffect, useRef, useState } from "react";
import { Select, Card, Avatar, Progress, Button, Modal } from "antd";
import { downloadIcon } from "../../assets/common";
import { RecommenderSystemService } from "../../Service";
import Navbar from "../Navbar/Navbar";
import { ColorList } from "./common";
import ReactToPrint from "react-to-print";
import "./styles.scss";

const recommenderSystemService = new RecommenderSystemService();

const { Option } = Select;
const { Meta } = Card;

function RecommenderSystem() {
  const [countries, setCountries] = useState([]);
  const [country, setCountry] = useState("");
  const [comodity, setComodity] = useState([]);
  const [color] = useState(ColorList);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [viewModel, setViewModel] = useState({});
  const [convertCurrency, setConvertCurrency] = useState(1);
  const isLoading = useState(true);

  let cardsDownload = useRef();
  let modelRef = useRef();

  const onCountrySearch = (value) => {
    console.log("search:", value);
  };

  const showModal = (recommendationList, index) => {
    setIsModalVisible(true);
    setViewModel({ ...recommendationList, index });
  };

  const AllTransaction =
    convertCurrency === 1
      ? "₹" + (viewModel?.currency / 1000000).toFixed(2) + " M"
      : "$" + (viewModel?.currency / 1000000).toFixed(2) + " M";

  const percentageCard = () => {
    const per = Object.values(viewModel?.state).reduce((accumulator, item) => {
      return accumulator + item[0];
    }, 0);
    return per;
  };

  const SelectCountry = async () => {
    try {
      setCountries(
        (await recommenderSystemService.getCountryList()).data?.country
      );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    SelectCountry();
  }, []);

  const ForRecommendation = async (convertCurrency) => {
    if (country !== "" && convertCurrency !== 0) {
      const { data } = await recommenderSystemService.getRecommendation({
        country: country,
        currency: convertCurrency,
      });
      setComodity(data.recommended_commodity);
    }
  };

  useEffect(() => {
    // ForRecommendation(convertCurrency);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [convertCurrency, isLoading]);

  const mapData =
    viewModel?.state === undefined ? [] : Object.entries(viewModel?.state);

  return (
    <div className="body">
      <Navbar />
      <div className="container">
        <div className="recommender">
          <div className="recommender-container ml-0">
            <p style={{ marginBottom: "0px" }}>Dual Recommender System</p>
            <p className="describe">
              Get commodities recommended for the country
            </p>
          </div>
          <div className="site-button-ghost-wrapper mr-0">
            <Button
              onClick={() => setConvertCurrency(1)}
              className={convertCurrency === 1 ? "rupee" : "rupeebtn"}
            >
              ₹ Rupee
            </Button>
            <Button
              onClick={() => setConvertCurrency(2)}
              className={convertCurrency === 2 ? "doller" : "dollerbtn"}
            >
              $ Dollar
            </Button>
          </div>
        </div>
      </div>

      <div className="container RecommendContainer">
        <div className="row">
          <div className="col-12">
            <div>
              <div className="heading">
                <p className="head">Select Country</p>
              </div>
              <div className="selectOptions">
                <Select
                  onSelect={() => ForRecommendation(convertCurrency)}
                  placeholder="Enter Country name to select"
                  value={country}
                  style={{ width: "300px" }}
                  showSearch
                  onChange={(e) => setCountry(e)}
                  onSearch={onCountrySearch}
                >
                  {countries?.map((list) => (
                    <Option key={list} value={list}>
                      {list}
                    </Option>
                  ))}
                </Select>
              </div>
            </div>
            <div className="radioRecommendation">
              <p className="NoOfRecommendtion"></p>
            </div>

            <div className="horizontalLine"></div>
            <div className="titles" id="titles">
              Recommended Commodities for {country}
              <ReactToPrint
                trigger={() => (
                  <img
                    className="pdfDownload"
                    src={downloadIcon}
                    alt="download"
                  />
                )}
                content={() => cardsDownload}
              />
            </div>
            {comodity?.length === 0 ? (
              <div className="title" id="title">
                <span className="imageTitle">No Recommendation</span>
                <div className="message">
                  Select country and submit
                  <br /> to view recommendation
                </div>
              </div>
            ) : (
              <div
                gutter={16}
                className="row rows"
                ref={(el) => (cardsDownload = el)}
              >
                {comodity?.map((recommendationList, index) => {
                  const Transaction =
                    convertCurrency === 1
                      ? "₹" +
                        (recommendationList?.currency / 1000000).toFixed(2) +
                        "M"
                      : "$" +
                        (recommendationList?.currency / 1000000).toFixed(2) +
                        "M";

                  const percentage = () => {
                    const per = Object.values(recommendationList?.state).reduce(
                      (accumulator, item) => {
                        return accumulator + item[0];
                      },
                      0
                    );
                    return per;
                  };

                  return (
                    <div
                      className="col-md-6 col-lg-4 col-sm-12"
                      key={recommendationList?.usd}
                      span={8}
                    >
                      <div className="my-3">
                        <Card className="mainCard">
                          <Meta
                            className="meta"
                            avatar={
                              <Avatar
                                style={{
                                  backgroundColor: color[index],
                                  verticalAlign: "middle",
                                }}
                                size="large"
                              >
                                {index + 1}
                              </Avatar>
                            }
                            title={recommendationList?.commodity}
                          />
                          <div className="countName">
                            <div className="TotalTransaction">
                              <p className="styles">Origin State </p>
                              <span className="originState">
                                {recommendationList?.stateCount} states
                              </span>
                            </div>
                            <span className="line"></span>
                            <div className="TotalTransaction">
                              <p className="styles">Total Transaction </p>
                              <span className="TotalTrasaction">
                                {Transaction}
                              </span>
                            </div>
                            <span className="line"></span>
                            <div className="ExportCountries">
                              <p className="styles">Export countries</p>
                              <span className="exportCountries">
                                {recommendationList?.countryCount} Country
                              </span>
                            </div>
                          </div>
                          <div>
                            {Object.entries(recommendationList?.state)
                              .slice(0, 3)
                              .map((item) => (
                                <div key={item}>
                                  <span
                                    className="stateName"
                                    onClick={() => {
                                      Modal.info({
                                        title: "Countries List",
                                        maskClosable: true,
                                        okButtonProps: {
                                          style: { display: "none" },
                                        },
                                        bodyStyle: {
                                          height: "380px",
                                          overflowY: "scroll",
                                        },
                                        content: (
                                          <div>
                                            <p className="CountryInfo">
                                              Countries
                                              <span>Total Export</span>
                                            </p>
                                            {Object.entries(item[1][2]).map(
                                              (countryNames) => (
                                                <p key={countryNames}>
                                                  {countryNames[0]}
                                                  <span
                                                    style={{ float: "right" }}
                                                  >
                                                    {(
                                                      countryNames[1] / 1000000
                                                    ).toFixed(2)}
                                                    M
                                                  </span>
                                                </p>
                                              )
                                            )}
                                          </div>
                                        ),
                                      });
                                      setTimeout(
                                        () =>
                                          document
                                            .getElementsByClassName(
                                              "ant-modal-body"
                                            )[0]
                                            .scrollTo(0, 0),
                                        100
                                      );
                                    }}
                                  >
                                    {item[0]}
                                  </span>
                                  <p className="statevalue">
                                    {(item[1][0] / 1000000).toFixed(2) + " M"}
                                    <span className="totalCountries">
                                      {" (country " + item[1][1] + ")"}
                                    </span>
                                  </p>
                                  <br />
                                  <Progress
                                    percent={(item[1][0] / percentage()) * 100}
                                    strokeColor={color[index]}
                                    showInfo={false}
                                  />
                                </div>
                              ))}

                            <div className="viewAll">
                              <p
                                onClick={() =>
                                  showModal(recommendationList, index)
                                }
                              >
                                View All
                              </p>
                            </div>
                          </div>
                        </Card>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
            <Modal
              title=""
              className="InfoModal"
              footer={null}
              visible={isModalVisible}
              onOk={() => setIsModalVisible(false)}
              onCancel={() => setIsModalVisible(false)}
              height={"500px"}
            >
              <div
                ref={(el) => (modelRef = el)}
                style={{ padding: "24px" }}
                className="print"
              >
                <Meta
                  className="meta"
                  avatar={
                    <Avatar
                      style={{
                        backgroundColor: color[viewModel?.index],
                        verticalAlign: "middle",
                      }}
                      size="large"
                    >
                      {viewModel?.index + 1}
                    </Avatar>
                  }
                  title={viewModel?.commodity}
                />
                <ReactToPrint
                  trigger={() => (
                    <img
                      className="pdfDownload"
                      src={downloadIcon}
                      alt="download"
                    />
                  )}
                  content={() => modelRef}
                />
                <div className="countName1">
                  <div className="d-flex flex-column ">
                    <p className="styles  origin">Origin State </p>
                    <span className="originState">
                      {viewModel?.stateCount} states
                    </span>
                  </div>
                  <span className="line1"></span>
                  <div className="TotalTransaction">
                    <p className="styles" id="currency">
                      Total Transaction
                    </p>
                    <span className="TotalTrasaction">{AllTransaction} </span>
                  </div>
                  <span className="line1"></span>
                  <div className="ExportCountries">
                    <p className="styles">Export countries</p>
                    <span className="exportCountries">
                      {viewModel?.countryCount} Country
                    </span>
                  </div>
                </div>
                <div>
                  {mapData.map((item) => (
                    <div key={item}>
                      <span
                        className="stateName"
                        onClick={() => {
                          Modal.info({
                            title: "Countries List",
                            maskClosable: true,
                            okButtonProps: { style: { display: "none" } },
                            bodyStyle: { height: "380px", overflowY: "scroll" },
                            content: (
                              <div>
                                <p className="CountryInfo">
                                  Countries <span>Total Export</span>
                                </p>
                                {Object.entries(item[1][2]).map(
                                  (countryNames) => (
                                    <p key={countryNames}>
                                      {countryNames[0]}
                                      <span style={{ float: "right" }}>
                                        {(countryNames[1] / 1000000).toFixed(2)}
                                        M
                                      </span>
                                    </p>
                                  )
                                )}
                              </div>
                            ),

                            onOk() {},
                          });
                          setTimeout(
                            () =>
                              document
                                .getElementsByClassName("ant-modal-body")[1]
                                .scrollTo(0, 0),
                            100
                          );
                        }}
                      >
                        {item[0]}
                        <p className="statevalue">
                          {(item[1][0] / 1000000).toFixed(2) + " M"}
                          <span className="totalCountries">
                            {" (country " + item[1][1] + ")"}
                          </span>
                        </p>
                      </span>
                      <Progress
                        percent={(item[1][0] / percentageCard()) * 100}
                        strokeColor={color[viewModel?.index]}
                        showInfo={false}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommenderSystem;
