import { useState, useEffect, useRef } from "react";
import { downloadIcon } from "../../assets/common";
import { DashboardService } from "../../Service";
import { Select, Button, Radio, Modal, Pagination } from "antd";
import { Pie, Column } from "@ant-design/plots";
import { chartColors, convertToInternationalCurrencySystem } from "./common";
import "./style.scss";
import Navbar from "../Navbar/Navbar";
import ReactToPrint from "react-to-print";
import Loader from "../Loader";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
);

const onCommoditySearch = (value) => {
  console.log("search:", value);
};

const onCountrySearch = (value) => {
  console.log("search:", value);
};

const { Option } = Select;

function Dashboard() {
  const [dashboardData, setDashboarData] = useState("");
  const [commoditydashboardData, setCommoditydashboardData] = useState("");
  const [currencydashboard, setCurrencydashboard] = useState("");
  const [year, setYear] = useState("2021");
  const [type, setType] = useState("country");
  const [countrystateoption, setCountrStateoption] = useState(1);
  const [bardashboardData, setBardashboardData] = useState([]);
  const [commodityData, setCommodityData] = useState([]);
  const [countrystatebarchart, setCountrystatebarchart] = useState([]);
  const [countrystate, setCountrystate] = useState("");
  const [currency, setCurrency] = useState("rupee");
  const [selectcommodity, setSelectcommodity] = useState("");
  const [charttype, setCharttype] = useState("barchart");
  const [isloading, setIsloading] = useState(true);
  const [tableList, setTableList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const dashboardService = new DashboardService();
  let countrystatepdf = useRef();
  let commoditypdf = useRef();
  let barchartpdf = useRef();

  useEffect(() => {
    setTimeout(() => setIsloading(false), 2500);
  }, []);

  const getCountryChartData = async () => {
    try {
      setDashboarData([]);
      const { data } = await dashboardService.getCountryChartData({
        year,
        name: type,
      });
      setDashboarData(data);
      setCurrencydashboard(data);
    } catch (error) {
      console.log(error);
    }
  };

  const currencyChange =
    currency === "rupee" ? "Values in INR " : "Values in USD";

  useEffect(() => {
    getCountryChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, type, countrystateoption]);

  const List = async (pageNo) => {
    try {
      setTableList((await dashboardService.getTableList(pageNo)).data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    List(pageNo);
  }, [pageNo]);

  const paginationChange = (value) => {
    setPageNo(value);
  };

  const getCommodityChartData = async () => {
    try {
      setCommoditydashboardData([]);
      const { data } = await dashboardService.getCountryChartData({
        year,
        name: type,
      });
      setCommoditydashboardData(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCommodityChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getbarChartData = async () => {
    const { data } = await dashboardService.getbarChartData({
      options: countrystateoption,
    });
    setBardashboardData(data[0]?.selection);
    setCountrystate(data[0]?.selection[0]);
  };

  useEffect(() => {
    getbarChartData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrystateoption]);

  const getcountrystatebarchart = async (value) => {
    const { data } = await dashboardService.getcountrystatebarchart({
      name: countrystate,
      commodity: value,
    });
    setCountrystatebarchart(data);
  };

  useEffect(() => {
    if (selectcommodity !== "" && countrystate !== "")
      getcountrystatebarchart(selectcommodity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectcommodity, countrystate]);

  const csInput = (e) => {
    setCountrystate(e);
  };

  const getcommodityData = async (value) => {
    const { data } = await dashboardService.getcommodityData({
      stateCountry: value,
    });
    setCommodityData(data?.commodityList);
    setSelectcommodity(data?.commodityList[0]);
  };

  useEffect(() => {
    getcommodityData(countrystate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countrystate]);

  const piechartDatacountry = (value) => {
    const countryArr = value
      ?.map(({ country, percent }) => {
        const changedvalue = parseFloat(percent.toFixed(2));
        return { type: country, value: changedvalue };
      })
      .filter(({ value }) => (charttype === "piechart" ? value > 0 : value));
    return countryArr;
  };

  const piechartDatastate = (value) => {
    const stateArr = value?.map(({ state, percent }) => {
      const changedvalue = parseFloat(percent.toFixed(2));
      return { type: state, value: changedvalue };
    });
    return stateArr;
  };

  const commodityValue = (value) => {
    const secondArr = value?.map(({ commodity, percent }) => {
      const changedvalue = parseFloat(percent.toFixed(2));
      return { type: commodity, value: changedvalue };
    });
    return secondArr;
  };

  const barchartData = () => {
    const barArr =
      countrystatebarchart?.length > 0
        ? countrystatebarchart[1]?.sortBarvalue?.map((value) => {
            if (countrystateoption === 2)
              return {
                type: value?.country,
                sales: value?.total_export / 1000000,
              };
            else
              return {
                type: value?.state,
                sales: value?.total_export / 1000000,
              };
          })
        : [];
    return barArr;
  };

  const configerCountry = {
    appendPadding: 10,
    data: piechartDatacountry(dashboardData[0]?.countryGrowth),
    angleField: "value",
    colorField: "type",
    color: chartColors,
    radius: 0.9,
    tooltip: {
      title: "Country Growth Percentage %",
      showTitle: true,
    },
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ value }) => `${value}%`,
      style: {
        fontSize: 10,
        textAlign: "center",
        fill: "black",
        fontWeight: "bold",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const configerstate = {
    appendPadding: 10,
    data: piechartDatastate(dashboardData[0]?.stateGrowth),
    angleField: "value",
    colorField: "type",
    color: chartColors,
    radius: 0.9,
    tooltip: {
      title: "State Growth Percentage %",
      showTitle: true,
    },
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ value }) => `${value}%`,
      style: {
        fontSize: 10,
        textAlign: "center",
        fill: "black",
        fontWeight: "bold",
      },
    },

    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const configer = {
    appendPadding: 10,
    data: commodityValue(commoditydashboardData[0]?.commodityGrowth),
    angleField: "value",
    colorField: "type",
    color: chartColors,
    radius: 0.9,
    tooltip: {
      title: "Commodity Growth Percentage %",
      showTitle: true,
    },
    label: {
      type: "inner",
      offset: "-30%",
      content: ({ value }) => `${value}%`,
      style: {
        fontSize: 10,
        textAlign: "center",
        fill: "black",
        fontWeight: "bold",
      },
    },
    interactions: [
      {
        type: "element-active",
      },
    ],
  };

  const onToggleChange = (e) => {
    setCountrStateoption(e.target.value);
  };

  const configerbarchart = {
    color: "#243386",
    data: barchartData(),
    xField: "type",
    yField: "sales",
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: true,
        fontSize: "10px",
      },
    },
    meta: {
      sales: {
        alias: "Total-Export(Millions)",
      },
    },
  };

  const options = {
    tooltips: { mode: "index", intersect: false },
    responsive: true,
    plugins: {
      tooltip: {
        callbacks: {
          title: function (context) {
            const titles = commodityValue(
              commoditydashboardData[0]?.commodityGrowth
            )?.map((data) => {
              return data.type;
            });

            for (let i = 0; i < titles.length; i++) {
              if (titles[i].split(" ")[0] === context[0].label) {
                return titles[i];
              }
            }
          },
        },
      },
      legend: {},
      title: {
        display: true,
      },
    },
  };

  const labels = commodityValue(
    commoditydashboardData[0]?.commodityGrowth
  )?.map((data) => {
    return data.type.split(" ")[0];
  });

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Commodity Growth Percentage %",
        data: commodityValue(commoditydashboardData[0]?.commodityGrowth)?.map(
          (data) => {
            return data.value;
          }
        ),
        backgroundColor: "#1BAF4E",
      },
    ],
  };

  const optionscountry = {
    responsive: true,
    plugins: {
      legend: {},
      title: {
        display: true,
      },
    },
  };

  const countrylabels = piechartDatacountry(
    dashboardData[0]?.countryGrowth
  )?.map((data) => {
    return data.type;
  });

  const countrydata = {
    labels: countrylabels,
    datasets: [
      {
        label: "Country Growth Percentage %",
        data: piechartDatacountry(dashboardData[0]?.countryGrowth)?.map(
          (data) => {
            return data.value;
          }
        ),
        backgroundColor: "red",
      },
    ],
  };

  const optionsstate = {
    tooltips: { mode: "index", intersect: false },
    responsive: true,
    plugins: {
      legend: { display: false },
      title: {
        display: true,
      },
    },
  };

  const statelabels = piechartDatastate(dashboardData[0]?.stateGrowth)?.map(
    (data) => {
      return data.type;
    }
  );

  const statedata = {
    labels: statelabels,
    datasets: [
      {
        label: "State Growth Percentage %",
        data: piechartDatastate(dashboardData[0]?.stateGrowth)?.map((data) => {
          return data.value;
        }),
        backgroundColor: "#0DB8B7",
        fontSize: "10px",
      },
    ],
  };

  return (
    <>
      <Navbar />
      {isloading === false ? (
        <div className="container" style={{ paddingTop: "70px" }}>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-12 px-0">
              <div className="exportDashboard">
                <p className="exportHeader">Export Dashboard</p>
              </div>
            </div>
            <div className="col-lg-3 col-md-3 col-sm-12 exportedData">
              <Button
                type="primary"
                onClick={() => setIsModalVisible(true)}
                id="TableList"
              >
                Single Transaction
              </Button>
              {isModalVisible && (
                <Modal
                  className="modalClass"
                  // id="exportedData"
                  title="List of One Time Exported Data "
                  visible={isModalVisible}
                  onOk={() => setIsModalVisible(false)}
                  onCancel={() => setIsModalVisible(false)}
                  width={"75%"}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">STATE</th>
                        <th scope="col">COUNTRY</th>
                        <th scope="col">COMMODITY</th>
                        <th scope="col">INR-2020</th>
                        <th scope="col">USD-2020</th>
                        <th scope="col">INR-2021</th>
                        <th scope="col">USD-2021</th>
                      </tr>
                    </thead>
                    <tbody>
                      {tableList?.data?.map((data, index) => {
                        return (
                          <>
                            {index > 20 && (
                              <tr key={index}>
                                <td>{data?.state}</td>
                                <td>{data?.country}</td>
                                <td>{data?.commodity}</td>
                                <td>{data?.inr_2020}</td>
                                <td>{data?.usd_2020}</td>
                                <td>{data?.inr2021}</td>
                                <td>{data?.usd_2021}</td>
                              </tr>
                            )}
                          </>
                        );
                      })}
                    </tbody>
                  </table>
                  <Pagination
                    current={pageNo}
                    onChange={paginationChange}
                    total={390}
                  />
                </Modal>
              )}
            </div>

            <div className="col-lg-6 col-md-6 col-sm-12">
              <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  <div className="yearSelect">
                    <Select
                      showSearch
                      style={{
                        width: 200,
                      }}
                      placeholder="2021"
                      onChange={(e) => setYear(e)}
                    >
                      <Option value="2021">2021</Option>
                      <Option value="2020">2020</Option>
                    </Select>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 px-0 mx-0">
                  <div id="currency-btn">
                    <Button
                      onClick={() => setCurrency("rupee")}
                      className={
                        currency === "rupee" ? "onclick-rupee-btn" : "rupee-btn"
                      }
                    >
                      ₹ Rupee
                    </Button>
                    <Button
                      onClick={() => setCurrency("dollar")}
                      className={
                        currency === "dollar"
                          ? "onclick-dollar-btn"
                          : "dollar-btn"
                      }
                    >
                      $ Dollar
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              style={{ paddingLeft: "0px" }}
            >
              <div className="total-exportvalue">
                <div className=" exportvalue-header">
                  <p className="textExports">Total Exports</p>
                  <p className="value-header">{currencyChange}</p>
                </div>
                <div className="value">
                  <label className="amountLabel">
                    {currency === "rupee"
                      ? " ₹ " +
                        currencydashboard[0]?.totalExpotsinr.toLocaleString(
                          "en-IN"
                        )
                        ? " ₹ " +
                          currencydashboard[0]?.totalExpotsinr.toLocaleString(
                            "en-IN"
                          )
                        : 0
                      : "$ " +
                        convertToInternationalCurrencySystem(
                          currencydashboard[0]?.totalExpotsus
                        )
                      ? "$ " +
                        convertToInternationalCurrencySystem(
                          currencydashboard[0]?.totalExpotsus
                        )
                      : 0}
                  </label>
                </div>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              style={{ paddingRight: "0px" }}
            >
              <div className=" total-yot-header">
                <p className="textexports">Total YoY Growth</p>
                <label className="totalPercent">
                  {year === "2021" ? "133.64%" : ""}
                </label>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 mt-3 mx-0 px-0 total-export-growth-charts">
              <div className="total-export-growth-header">
                <h3 className="exportHeader">Total Export growth</h3>
                <div className="chart-btn">
                  <div
                    className="site-button-ghost-wrapper"
                    id="barchart-piechart-btn"
                  >
                    <Button
                      className={
                        charttype === "barchart"
                          ? "onclick-barchart-btn"
                          : "barchart"
                      }
                      onClick={() => setCharttype("barchart")}
                    >
                      BarChart
                    </Button>
                    <Button
                      className={
                        charttype === "piechart"
                          ? "onclick-piechart-btn"
                          : "piechart"
                      }
                      onClick={() => setCharttype("piechart")}
                    >
                      PieChart
                    </Button>
                  </div>
                </div>
              </div>
            </div>
            <div className="row mx-0 px-0 mt-1">
              <div
                className="col-lg-6 col-xl-6 col-md-12 px-0"
                ref={(e) => (countrystatepdf = e)}
              >
                <div className="pie-chart-state-country">
                  <div className="piechart-header">
                    <p className="chartHeader">
                      Total Export growth in Percentage for Top 10
                    </p>
                    <ReactToPrint
                      trigger={() => (
                        <img
                          src={downloadIcon}
                          alt=""
                          className="state-country-pdf"
                        />
                      )}
                      content={() => countrystatepdf}
                    />
                  </div>
                  <div className="state-country-btn">
                    <div
                      className="site-button-ghost-wrapper"
                      id="state-country"
                    >
                      <Button
                        className={
                          type === "country" ? "onclick-country-btn" : "country"
                        }
                        onClick={() => setType("country")}
                      >
                        Countries
                      </Button>
                      <Button
                        className={
                          type === "state" ? "onclick-states-btn" : "states"
                        }
                        onClick={() => setType("state")}
                      >
                        States
                      </Button>
                      <p className="state-country-btnheader">
                        * From 2020 - 2021
                      </p>
                    </div>
                  </div>
                  <div className="pie-chart-1">
                    {charttype === "barchart" &&
                    dashboardData[0]?.countryGrowth ? (
                      <Bar
                        options={optionscountry}
                        data={countrydata}
                        height={"180px"}
                      />
                    ) : charttype === "piechart" &&
                      dashboardData[0]?.countryGrowth ? (
                      <Pie {...configerCountry} />
                    ) : null}
                    {charttype === "barchart" &&
                    dashboardData[0]?.stateGrowth ? (
                      <Bar
                        options={optionsstate}
                        data={statedata}
                        height={"180px"}
                      />
                    ) : charttype === "piechart" &&
                      dashboardData[0]?.stateGrowth ? (
                      <Pie {...configerstate} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className="col-lg-6 col-xl-6 col-md-12 px-0"
                ref={(e) => (commoditypdf = e)}
              >
                <div className="pie-chart-commodity">
                  <div className="chartheader-commodity">
                    <p className="commodityPercentage-header">
                      Total Export growth in percentage for Top 10 commodities
                    </p>
                    <div className="pie-commocity-pdf">
                      <ReactToPrint
                        trigger={() => (
                          <img
                            src={downloadIcon}
                            alt=""
                            className="barchart-pdf"
                          />
                        )}
                        content={() => commoditypdf}
                      />
                    </div>
                  </div>
                  <p className="commodityYear">* From 2020 - 2021</p>
                  <div className="pie-chart-2">
                    <div className="pie-chart-wrapper">
                      {charttype === "barchart" &&
                      commoditydashboardData[0]?.commodityGrowth ? (
                        <Bar options={options} data={data} height={"180px"} />
                      ) : charttype === "piechart" &&
                        commoditydashboardData[0]?.commodityGrowth ? (
                        <Pie {...configer} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-12 col-md-12 col-sm-12 barchart mt-3"
              ref={(e) => (barchartpdf = e)}
            >
              <div className="barchart-header">
                <p className="commoditiesgraph">Commodities Graph</p>
                <div className="bargraphRadio-btn">
                  <Radio.Group
                    onChange={onToggleChange}
                    value={countrystateoption}
                  >
                    <Radio value={1}>Destination Country</Radio>
                    <Radio value={2}>Origin State</Radio>
                  </Radio.Group>
                </div>
                <div className="select-btn">
                  <div className="country-state-select">
                    <Select
                      onChange={csInput}
                      placeholder="All Countries"
                      value={countrystate}
                      style={{ width: "300px" }}
                      showSearch
                      onSearch={onCountrySearch}
                    >
                      {bardashboardData?.map((data) => (
                        <Option value={data}>{data}</Option>
                      ))}
                    </Select>
                  </div>
                  <div className="commodity-select">
                    <Select
                      onSelect={(val) => setSelectcommodity(val)}
                      placeholder="All Commodities"
                      value={selectcommodity}
                      style={{ width: "300px" }}
                      showSearch
                      onSearch={onCommoditySearch}
                    >
                      {commodityData?.map((data) => (
                        <Option value={data}>{data}</Option>
                      ))}
                    </Select>
                  </div>
                  <ReactToPrint
                    trigger={() => (
                      <img src={downloadIcon} alt="" className="barchart-pdf" />
                    )}
                    content={() => barchartpdf}
                  />
                </div>
              </div>
              <p className="barchart-country-state-header">
                All Commodities <span className="barchart-for"> for </span>
                <span>
                  {countrystateoption === 1 ? "All Country" : "All state"}
                </span>
              </p>
              <div className="row barchartWrap">
                <div className="col-xl-2 col-lg-2 col-md-2 col-sm-2">
                  <div className="bargraph-display">
                    <div className="bargraphchartwrap">
                      <p className="total-export">Total Export (Millions) </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-10 col-lg-10 col-md-10 col-sm-10">
                  <div className="barchartData">
                    <Column {...configerbarchart} />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <p className="barchartSummary">
                    {/* <span className="summary">SUMMARY : </span>
                      {countrystatebarchart?.length > 0
                        ? countrystatebarchart[2]?.summary
                        : ""} */}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div></div>
        </div>
      ) : (
        <div className="containerLoader">
          <Loader />
        </div>
      )}
    </>
  );
}
export default Dashboard;
