import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { EyeInvisibleOutlined, EyeTwoTone } from "@ant-design/icons";
import { Input } from "antd";
import { Button } from "react-bootstrap";
import { diatozLogo } from "../../assets/common";
import { Link } from "react-router-dom";
import Trade from "../../assets/common/TradeAnalytics.svg";
import "./styles.scss";
import { LoginService } from "./service";

const credentials = {
  id: "01",
  UserName: "Admin123",
  Password: "dream@123",
};

function Login() {
  const userRef = useRef();
  const [email, setEmail] = useState("");
  const [isEmailError, setIsEmailError] = useState(false);
  const [password, setPassword] = useState("");
  const [data, setData] = useState([]);
  const [submitButtonEnable, setSubmitButtonDisable] = useState(false);

  const loginFormData = new LoginService();
  const loginForm = async (e) => {
    e.preventDefault();
    const payload = {
      email,
      password,
    };
    const user = JSON.parse(localStorage.getItem("user"));
    if (user.email === email && user.confirmPassword === password) {
      try {
        await loginFormData.addLoginFormData(payload);
        localStorage.setItem("auth", true);
        Navigate("dashboard");
      } catch (error) {
        localStorage.setItem("auth", false);
      }
    } else {
    }
  };

  const handleemail = (e) => {
    setEmail(e.target.value);
  };

  const handlepassword = (e) => {
    setPassword(e.target.value);
  };

  const Navigate = useNavigate();

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => setData(credentials.UserName), []);
  useEffect(() => setData(credentials.Password), []);

  useEffect(() => {
    setIsEmailError(email ? !isEmail(email) : false);
  }, [email]);

  useEffect(() => {
    if (password === "" || email === "") {
      setSubmitButtonDisable(true);
    } else {
      setSubmitButtonDisable(false);
    }
  }, [password, email]);

  return (
    <form className="main-form h-100">
      <div
        id="main-div"
        className="register-page h-100"
        style={{ overflow: "hidden" }}
      >
        <div className="logos-container">
          <img className="diatoz-logo" src={diatozLogo} alt="diatoz" />

          <div className="logos-separator"></div>
          <img className="logo" src={Trade} alt="niti-aayog" />
        </div>
        <div
          style={{ height: "calc(100% - 60px)" }}
          className="d-flex align-items-center"
        >
          <div id="card-div" className="login-container">
            <div className="login">Login</div>
            <div className="email-content">
              <label className="Labels">Email ID</label>

              <Input
                value={email}
                name="email"
                onChange={handleemail}
                className="password-input"
                placeholder="Enter your company email id"
                ref={userRef}
                required
              />
              {isEmailError && (
                <span className="text-danger">Enter valid email id</span>
              )}
            </div>
            <div className="password-content">
              <label className="Labels">Password </label>
              <Input.Password
                value={password}
                name="password"
                onChange={handlepassword}
                className="password-input"
                placeholder="Enter password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
                required
              />
            </div>

            <div className="login-terms">
              <p>
                By clicking 'Login', you agree to our
                <Link className="term mx-1" to={"/terms-and-conditions"}>
                  Terms of Use
                </Link>
                and
                <Link className="term mx-1" to={"/privacy-and-policy"}>
                  Privacy Policies
                </Link>
              </p>
            </div>

            <Button
              type="submit"
              className="submit"
              onClick={(e) => loginForm(e)}
              disabled={submitButtonEnable}
            >
              Login
            </Button>
            <span className="terms">
              Don't have an account?
              <Link className="register-button-link" to={"/register"}>
                Register
              </Link>
            </span>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Login;
