import { ApiHelper } from "../../Helper/ApiHelper";

const apiHelper = new ApiHelper();

export class DashboardService {
  getCountryChartData(data) {
    const uri = "/v1/growth";
    return apiHelper.post(uri, data);
  }

  getbarChartData(data) {
    const uri = "/v1/selection";
    return apiHelper.post(uri, data);
  }
  getcountrystatebarchart(data) {
    const uri = "/v1/barchart";
    return apiHelper.post(uri, data);
  }
  getcommodityData(data) {
    const uri = "/v1/input";
    return apiHelper.post(uri, data);
  }
  getTableList(pageNo) {
    const uri = "/v1/nanList?page_num=" + pageNo + "&pages_size=1000";
    return apiHelper.get(uri);
  }
}
