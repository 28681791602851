import { useNavigate } from "react-router-dom";
import { Button, Modal } from "antd";
import CheckIcon from "../../assets/common/check.svg";
import "./register.scss";
const PopupModal = ({ isVisible }) => {
  const Navigate = useNavigate();
  const handleLogin = () => {
    Navigate("/");
  };
  return (
    <div className="main-model">
      <Modal
        keyboard={false}
        centered
        className="popup-model"
        footer={
          <>
            <div className="row justify-content-around">
              <Button onClick={handleLogin} className="login-button">
                Login
              </Button>
            </div>
          </>
        }
        visible={isVisible}
        closable={false}
      >
        <div className="check-icon">
          <img className="check-icon" src={CheckIcon} alt="check-img" />
        </div>
        <div>
          <span className="popup-text">Registration successfull</span>
        </div>

        <div className="popup-content">
          <div className="span-content">
            Your account has been created successfully
          </div>
          <div className="span-content-login">Please login to continue</div>
        </div>
      </Modal>
    </div>
  );
};
export default PopupModal;
