import { ApiHelper } from "../../Service/Helper/ApiHelper";

const apiHelper = new ApiHelper();

export class LoginService {
  addLoginFormData(payload) {
    const uri = `/v1/signin`;
    return apiHelper.post(uri, payload);
  }
}
