import React from "react";
import "./policy.scss";
import Trade from "../../assets/common/TradeAnalytics.svg";
import { Link } from "react-router-dom";

function PrivacyPolicy() {
  return (
    <div className="card h-100">
      <nav className="navbar  shadow-sm p-4 mb-5 bg-white rounded">
        <img className="logo" src={Trade} alt="trade" />
        <form className="termsForm">
          <Link to={"/"} className="btn btn-outline my-2 my-sm-0" type="submit">
            Login
          </Link>
          <Link
            to={"/register"}
            className="btn btn-outline my-2 my-sm-0"
            type="submit"
          >
            Register
          </Link>
        </form>
      </nav>
      <div className="termsContentContainer">
        <h5 className="termsHeading">Privacy Policies</h5>
        <span className="termsContent">
          <h6 className="contentHeading"> Introduction:</h6>
          <p>
            DIATOZ (the "Company," "we," or "us") respects your privacy and is
            committed to protecting it through our compliance with this privacy
            policy (the "Policy"). This Policy describes the types of
            information we may collect from you or that you may provide when you
            use our platform and our practices for collecting, using,
            maintaining, protecting, and disclosing that information. For the
            purposes of the Privacy Policy, "You" or "Your" shall mean the
            person who is accessing the tool.
          </p>
          <h6 className="contentHeading">Information Collection :</h6>
          <p>
            We may collect information from and about users of our platform in a
            variety of ways, including: information you provide directly to us,
            such as contact information termed as “Personal Information” (PI)
            like Name, Address, Password, email, and transaction details.
            Information we automatically collect when you use our platform, such
            as your IP address, browser type, operating system, and usage data
            from cookies. Information we collect from third-party sources, such
            as market data providers, to supplement the information we collect
            directly from you. Use of Information: We may use the information we
            collect from and about you for various purposes, including to:
            <br />
            <br />
            Provide our platform's functionality and services to you. Analyze
            usage patterns and improve our platform and services. Comply with
            legal obligations and resolve disputes. Disclosure of Information:
            We may disclose information we collect from and about you to:
            <br />
            <br />
            Third-party service providers we use to support our platform and
            services. Our business partners with whom we collaborate to provide
            services to you. Government agencies or other third parties as
            required by law or to protect our legal rights. Data Security: We
            take reasonable steps to protect the information we collect from and
            about you from unauthorized access, use, and disclosure. However, no
            method of transmission over the internet or electronic storage is
            completely secure.
          </p>
          <h6 className="contentHeading"> Changes to this Policy:</h6>{" "}
          <p>
            We may update this Policy from time to time. If we make material
            changes, we will notify you by email or by posting a notice on our
            platform.
          </p>
          <h6 className="contentHeading"> Disclaimer:</h6>
          <p>
            The Company does not store any account related information or any
            credit / debit card details. Company shall not be liable for any
            loss or damage sustained by Users as a result of any disclosure
            (inadvertent or otherwise) of any information concerning the User's
            account, credit cards or debit cards in the course of any online
            transactions or payments made for any products and/or services
            offered through the Platform.
            <br />
            <br /> In case any Personal Information is shared by you with
            Company, which is not requested by Company during registration,
            (whether mandatorily or optionally), Company will not be liable for
            any information security breach or disclosure in relation to such
            information.
          </p>
          <h6 className="contentHeading"> Contact Us:</h6>
          <p>
            If you have any questions, concerns, grievances and/or complaints
            about this Policy, please contact us at privacy@tradeanalytics.com.
            <br />
            <br />
            By using our platform, you agree to the terms of this Policy. If you
            do not agree to the terms of this Policy, do not use our platform.
          </p>
        </span>
      </div>
    </div>
  );
}

export default PrivacyPolicy;
