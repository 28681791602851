import React from "react";
import "./terms.scss";
import Trade from "../../assets/common/TradeAnalytics.svg";
import { Link } from "react-router-dom";

function TermsAndConditions() {
  return (
    <div className="card h-100">
      <nav className="navbar shadow-sm p-4 mb-5 bg-white rounded">
        <img className="logo" src={Trade} alt="trade" />
        <form className="termsForm">
          <Link to={"/"} className="btn btn-outline my-2 my-sm-0" type="submit">
            Login
          </Link>
          <Link
            to={"/register"}
            className="btn btn-outline my-2 my-sm-0"
            type="submit"
          >
            Register
          </Link>
        </form>
      </nav>
      <div className="termsContentContainer">
        <h5 className="termsHeading">Terms of Use</h5>
        <span className="termsContent">
          <p>The following are the terms of use for trade analytics:</p>
          <h6 className="contentHeading">1 Ownership :</h6>
          <p className="innerContent">
            The Trade Analytics tool and all associated intellectual property
            rights belong to the Diatoz. By using the tool, you agree not to
            reproduce, duplicate, copy, sell, trade, resell or exploit for any
            commercial purposes, any portion of the tool, use of the tool, or
            access to the tool.
          </p>
          <h6 className="contentHeading">2 No investment advice :</h6>
          <p>
            The Trade Analytics tool is not intended to provide investment
            advice or recommendations. The tool is solely for informational
            purposes only and should not be relied upon as a substitute for
            professional advice. DIATOZ does not guarantee the accuracy or
            completeness of any information provided by the tool.
          </p>
          <h6 className="contentHeading">3 Your responsibility :</h6>
          <p>
            You are responsible for your use of the tool and the results
            obtained from the tool. DIATOZ shall not be liable for any loss or
            damage caused by your reliance on the information obtained from the
            tool. You give us permission to use the information about actions
            that you have taken on TradeAnalytics content (whether sponsored or
            not) that we display across our services, without any compensation
            to you. We use data and information about you to make relevant
            suggestions, recommendations and any improvements internally
            required in the tool.
          </p>
          <h6 className="contentHeading">4 Use limitations :</h6>
          <p>
            You agree not to use the tool for any illegal or unauthorized
            purpose. You also agree not to modify, adapt, or hack the tool or to
            interfere with its proper functioning.
          </p>
          <h6 className="contentHeading">5 Indemnification :</h6>
          <p>
            You agree to indemnify and hold Diatoz harmless from any claim or
            demand, including reasonable attorneys’ fees, made by any third
            party due to or arising out of your use of the tool, your violation
            of these terms of use, or your violation of any rights of another.
          </p>
          <h6 className="contentHeading">6 Modification :</h6>
          <p>
            DIATOZ reserves the right to modify these terms of use at any time.
            Any modifications shall be effective immediately upon posting on the
            Diatoz website. Your continued use of the tool after the
            modifications constitute your agreement to the modified terms of
            use.
          </p>
          <h6 className="contentHeading">7 Termination :</h6>
          <p>
            DIATOZ may terminate your access to the tool at any time without
            notice, including but not limited to your violation of these terms
            of use.
          </p>
          <h6 className="contentHeading">8 Governing Law :</h6>
          <p>
            These terms of use shall be governed by and construed in accordance
            with the laws of the jurisdiction in which the owner of the tool is
            located. By using the trade analytics tool, you acknowledge that you
            have read these terms of use and agree to be bound by them.
          </p>
        </span>
      </div>
    </div>
  );
}

export default TermsAndConditions;
