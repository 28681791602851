export const chartColors = [
  "#F36429",
  "#FCB716",
  "#FCE9A9 ",
  "#C7D92F",
  "#1BAF4E",
  "#0DB8B7 ",
  "#85DBDB",
  "#F16FA8",
  "#BB4545",
  "#E7A4C6",
  "#F3D5D6",
  "#909DA5",
  "#5B9ED2",
  "#82D1E2",
  "#CDEDF3",
  "#BB4545",
  "#DB7B7B",
  "#F7A5AC",
  "#787DC6",
  "#A4AAF7",
  "#DADDFC",
  "#707070",
  "#999999",
  "#666666",
  "#243386",
  "#00000029",
  "#717BFF",
  "#85DBDB",
  "#A6CEE3",
  "#F36429",
  "#39468D",
  "#247986",
];

export function convertToInternationalCurrencySystem(labelValue) {
  return Math.abs(Number(labelValue)) >= 1.0e6
    ? (Math.abs(Number(labelValue)) / 1.0e6).toLocaleString("en-IN") + "M"
    : Math.abs(Number(labelValue)) >= 1.0e3
    ? (Math.abs(Number(labelValue)) / 1.0e3).toLocaleString("en-IN") + "K"
    : Math.abs(Number(labelValue));
}
